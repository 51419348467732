<template>
  <div class="container prow-40">
    <h1 class="title pt-60 pb-10">
      {{ info.title }}
    </h1>
    <div class="f-fs-fs tip pt-30" v-if="info.title">
      <div class="pr-40 f-c-c">
        <img src="@/assets/time.png" class="icon pr-6" />
        <div>
          {{ info.createAt | timeFilter }}
        </div>
      </div>
      <div class="f-c-c">
        <img src="@/assets/browse.png" class="icon pr-6" />
        <div>
          {{ info.looks }}
        </div>
      </div>
    </div>
    <div class="mt-60" v-html="info.content"></div>
    <download-app :hidden="$route.query.hidden" />
  </div>
</template>

<script>
import { getItem } from '@/api/tweet';
import DownloadApp from '@/components/DownloadApp.vue';

export default {
  name: 'Tweet',

  components: {
    DownloadApp,
  },

  filters: {
    timeFilter: function (str) {
      if (!str) return '';
      return str.slice(0, 10);
    },
  },

  data: function () {
    return {
      info: {},
    };
  },

  watch: {
    '$route.query': {
      handler(obj) {
        if (obj.id) {
          this.loadData({
            id: obj.id,
          });
        }
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    async loadData(params) {
      const { data } = await getItem(params);
      this.info = data;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100vw;
  background-color: #fff;
}
.title {
  font-size: 0.28rem;
  color: #0a1738;
  line-height: 0.4rem;
  letter-spacing: 0.02rem;
}
.tip {
  color: #999;
  font-size: 0.24rem;
  line-height: 1;
}
.icon {
  width: 0.24rem;
  height: 0.24rem;
}
</style>
